const mixin = {
  methods: {
    back () { 
      this.$router.push('/stock')
    },
    handleCancel () { 
      this.$confirm({
        title: 'Are you sure you want to give up saving?',
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        onOk: this.back,
      });
    },
  },
}

export default mixin