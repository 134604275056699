<template>
  <div class="mainPage">
    <div class="flex justify-between">
      <p class="pageTitle"><a-icon @click="back" type="left" class="goBack"/>Stock base table management-add</p>
    </div>
    <div class="pageWrap">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="Country" prop="countryCode">
          <a-select v-model="form.countryCode">
            <a-select-option :value="item" v-for="item in countryCodeList" :key="item">
              {{item}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="Ship model ID" prop="recallModelId">
          <a-input v-model="form.recallModelId" :maxLength="50"/>
        </a-form-model-item>
        <a-form-model-item label="Color" prop="recallColor">
          <a-select v-model="form.recallColor">
            <a-select-option :value="item" v-for="item in colorList" :key="item">
              {{item}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="Ship SKU ID" prop="recallSkuId">
          <a-input-number v-model="form.recallSkuId" :min="1" :max="99999" :step="1" :precision="0"/>
        </a-form-model-item>
        <a-form-model-item label="Ship SKU number" prop="recallSku">
          <a-input v-model="form.recallSku" :maxLength="50"/>
        </a-form-model-item>
        <a-form-model-item label="Warehouse ID" prop="warehouse">
          <a-input-number v-model="form.warehouse" :min="1" :max="9999" :step="1" :precision="0"/>
        </a-form-model-item>
        <a-form-model-item label="Warehouse location" prop="warehouseAddress">
          <a-input v-model="form.warehouseAddress" :maxLength="500"/>
        </a-form-model-item>
        <a-form-model-item label="Available stock" prop="stock">
          <a-input-number v-model="form.stock" :min="0" :max="999999" :step="1" :precision="0"/>
        </a-form-model-item>
        <a-form-model-item label="Shipping Method" prop="shippingMethod">
          <a-input v-model="form.shippingMethod" :maxLength="10"/>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button class="mr24" @click="handleCancel">
            Back
          </a-button>
          <a-button type="primary" @click="onSubmit">
            Save
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import stockMixin from './stockMixin'

export default {
  mixins: [stockMixin],
  data () { 
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        countryCode: '',
        recallModelId: '',
        recallColor: '',
        recallSkuId: '',
        recallSku: '',
        warehouse: '',
        warehouseAddress: '',
        stock: '',
        shippingMethod: ''
      },
      rules: {
        countryCode: [
          { required: true, message: 'Please select shipping country', trigger: 'change' }
        ],
        recallModelId: [
          { required: true, message: 'Please enter model id', trigger: 'blur' }
        ],
        recallColor: [
          { required: true, message: 'Please select the color', trigger: 'change' }
        ],
        recallSkuId: [
          { required: true, message: 'Please select sku id', trigger: 'blur' }
        ],
        recallSku: [
          { required: true, message: 'Please enter sku number', trigger: 'blur' }
        ],
        warehouse: [
          { required: true, message: 'Please enter warehouse id', trigger: 'blur' }
        ],
        warehouseAddress: [
          { required: true, message: 'Please enter warehouse location', trigger: 'blur' }
        ],
        stock: [
          { required: true, message: 'Please enter availiable stock', trigger: 'blur' }
        ],
        shippingMethod: [
          { required: true, message: 'Please enter shipping method', trigger: 'blur' }
        ]
      }
    }
  },
  created () { 
    this.countryCodeList = ['US', 'MX', 'CA']
    this.colorList = ['Black', 'Red', 'White', 'Blue', 'Light Gray', 'Wine Red', 'Silver Black']
  },
  methods: {
    onSubmit () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.addStockBaseInfo()
        } else {
          return false;
        }
      });
    },
    async addStockBaseInfo () {
      const onOk = async () => { 
        await this.$http.post('/platform/admin/ticketAdminManage/v1/addStockBaseInfo', {
          ...this.form
        })
        this.back()
      }
      this.$confirm({
        title: 'Are you sure to save?',
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        onOk,
      });
      
    }
  }
}
</script>

<style lang="less" scoped>
.goBack{
  cursor: pointer;
  margin-right: 12px;
}
</style>
